import React, { useState } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Layer,
  Text,
  Button
} from "grommet";
import TextField from '@mui/material/TextField';
import {Box as BoxMui} from '@mui/material/';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { useLazyQuery,useMutation, useSubscription } from '@apollo/client';
import { tenant,tenantType, tenantTypeFirstUpperCase } from "../../tenant";
import {
  extractIdFormData,
  GOLF_ADD_ATTACHMENT,
  GOLF_GET_ATTACHMENT,
  QUERY_CONTENT_TYPE,
  sortTree
} from "../../../utilis/utils";
import { QUERY_GET_CLIENTS } from "../../activity/AutomaticheGQL";
import { QUERY_GET_CLIENT_PROFILE } from "./MachineProfileGQL";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProfileTab from "./ProfileTab";

export default function MachineProfile(props) {

    const [clientList, setClientList] = useState([]);
    const [showedClientList, setShowedClientList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [textSearch, setTextSearch] = useState("");
    const [profile, setProfile] = useState(null);
    const [valueTab, setValueTab] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValueTab(newValue);
    };

    const [ queryGetProfile
    ] = useLazyQuery(QUERY_GET_CLIENT_PROFILE, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_machines_machineprofile`];
          if(dataApp){
            console.log("GET PROFILE",dataApp)
            setProfile(dataApp[0])
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    const [ queryGetClients
    ] = useLazyQuery(QUERY_GET_CLIENTS, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if(data){
          const dataApp=data[`${tenant}_machines_machine${tenantType}`];
          if(dataApp){
            console.log("CLIENTS",dataApp)
            setClientList(dataApp)
          }
        }
      },
      notifyOnNetworkStatusChange: true, // did the work
    });

    React.useEffect(()=>{
      queryGetClients()
    },[])

    React.useEffect(()=>{
      setShowedClientList(clientList)
    },[clientList])

    React.useEffect(()=>{
        console.log("PROFILE",profile)
        console.log(Object.keys({}).length === 0)
    },[profile])

    React.useEffect(()=>{
      if(textSearch!==""){
        setShowedClientList(clientList.filter((client)=>{
          return client.name.toLowerCase().includes(textSearch.toLowerCase())
        }))
      }else{
        setShowedClientList(clientList)
      }
    },[textSearch])

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </div>
        );
      }
    

    return (
        <center>
            <Box width="50%" style={{ marginTop: "20px" }}>
                <TextField id="standard-basic" label="Ricerca per nome" variant="standard" onChange={(e)=>{setTextSearch(e.target.value)}} />
            </Box>
            
            <Box direction="row" style={{ 
                paddingTop: "10px", 
                paddingBottom: "10px",  
                backgroundColor: 'transparent', 
                margin: "40px", 
                height: "120px", 
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.25)", 
                overflowX: "scroll", // Cambiato overflowX da "scroll" a "auto"
                width: "900px"
            }}>
                
                {showedClientList.length>0?
                    showedClientList.map((client)=>{
                        return(
                            <Card style={{ 
                                height: "70px",
                                minWidth: "220px", 
                                margin: "0px!important", 
                                marginLeft: "30px" 
                            }} background="light-1" onClick={() => queryGetProfile({variables:{machine_id:client.id}})}>
                                <CardBody pad={"small"} style={{}} >
                                    {client.name}
                                </CardBody>
                            </Card>
                        )
                    })
                    :
                    null
                }               
            </Box>
            {profile!==undefined && profile!==null && Object.keys(profile).length != 0 && 
                        <ProfileTab profile={profile} valueTab={valueTab} handleChange={handleChange} />
            }

        </center>
    )
}