import {Box, Button, Image, Select, Sidebar, Text} from "grommet";
import {Analytics, Checkmark, Configure, Cubes, Home, List, MapLocation, Plan, UserWorker} from "grommet-icons";
import {Notification as NotificationApp} from "grommet-icons/icons";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft.js";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight.js";
import React from "react";
import SideBarButton from "./SideBarButton.jsx"
import {routes} from "../../routes.js";

function SideBarDesktop(props){
        return (
            <Sidebar
                width={!props.smallSideBar ? "300px" : "100px"}
                height={"100vh"}
                responsive={true}
                background="#FFFFFF"
                header={
                    <Box align="center" height="100vh">
                        {
                            !props.smallSideBar ?
                                <>
                                    <Box height="20px">
                                        {props?.ruoli && Array.isArray(props?.cantieri) && props?.cantieri.length > 0 ?
                                            (props?.ruoli?.Ruolo === 'Admin_Cantiere' ?
                                                    <Select
                                                        options={props?.cantieri}
                                                        labelKey={(option) => option?.name}
                                                        value={props?.newCantiere}
                                                        multiple={true}
                                                        closeOnChange={false}
                                                        onChange={(event) => props?.handleCantiere(event?.value)}
                                                    />
                                                    :
                                                    (props?.ruoli?.Ruolo === 'Admin_Zona' ?
                                                            <Select
                                                                options={props?.cantieri[0].Zona.map(cantiere => (cantiere))}
                                                                value={props?.newCantiere}
                                                                multiple={true}
                                                                onChange={({option}) => props?.handleCantiere(option)}
                                                            />
                                                            :
                                                            null
                                                    )
                                            )
                                            :
                                            null
                                        }
                                    </Box>
                                    <Box height="small" pad="small">
                                        <Image fit="contain" src="./images/Logo1.png"/>
                                    </Box>
                                </>
                                :
                                null
                        }
                        <Box ref={props?.scrollSidebar} width={"100%"} align="center" direction="column" gap="7px" style={{overflowX: "hidden"}}>
                            {
                                routes.map((route,index) => { 
                                    if(route?.role?.includes(props?.ruoli?.Ruolo) && (route?.auth==null || props?.functionPermission[route?.auth])){
                                        return(
                                            <SideBarButton actualPage={props?.actualPage} setPage={props?.setPage} smallSideBar={props?.smallSideBar} page={route?.name} key={index} name={route?.name} pageName={route?.pageName} icon={route?.icon}/>
                                        )
                                    }
                                })
                            }

                            <Button style={{
                                width: !props.smallSideBar ? '200px' : "50px",
                                fontSize: '10px',
                                background: props.page === "Notifiche" ? '#397aa3' : '#FFFFFF',
                                color: 'white',
                                fontWeight: 'bold',
                                textTransform: 'uppercase',
                                borderRadius: "20px"
                            }} onClick={() => {
                                props?.setPage("Notifiche")
                            }}
                                    badge={{
                                        background: props?.notifications?.length === 0 ? "none" : "status-critical",
                                        value: props?.notifications?.length === 0 ? false : props?.notifications?.length,
                                        max: 9,
                                        size: 'small'
                                    }}
                            >
                                <Box pad="small" direction="row" align="center" gap="medium">
                                    <NotificationApp color={props.page != "Notifiche" ? 'black' : '#FFFFFF'}/>
                                    {
                                        !props.smallSideBar &&
                                        <Text size="xsmall"
                                              color={props.page != "Notifiche" ? 'black' : '#FFFFFF'}>Notifiche</Text>
                                    }

                                </Box>
                            </Button>
                        </Box>
                    </Box>

                }

                footer=
                    {!props.smallSideBar ?
                        <Button style={{
                            width: "55px",
                            background: 'white',
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            borderRadius: "20px"
                        }} onClick={() => {
                            props?.setSmallSideBar(true);
                            sessionStorage.setItem("smallSidebar", true)
                        }}>
                            <Box pad="small" direction="row" align="center" gap="medium">
                                <ArrowCircleLeftIcon color='primary' fontSize="large"/>
                            </Box>
                        </Button>
                        :
                        <Button style={{
                            width: "55px",
                            background: 'white',
                            color: 'white',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            borderRadius: "20px"
                        }} onClick={() => {
                            props?.setSmallSideBar(false);
                            sessionStorage.setItem("smallSidebar", false)
                        }}>
                            <Box pad="small" direction="row" align="center" gap="medium">
                                <ArrowCircleRightIcon fontSize="large"/>
                            </Box>
                        </Button>
                    }
            >
            </Sidebar>
        )
};

export default SideBarDesktop