import {
    Home,
    Analytics,
    UserWorker,
    List,
    Dashboard,
    Location,
    Cubes,
    Configure, Checkmark, InProgress,ContactInfo
} from "grommet-icons";
import HomePage from "./Home.js";
import AttivitaDet1 from "./components/activity/AttivitaDet1.js";
import Automatiche from "./components/activity/automatiche.js";
import Anagrafiche from "./components/registryComponent/Anagrafiche.js";
import Manutenzione from "./components/maintenance/Manutenzione.js";
import WareHouse from "./components/wareHouse/WareHouse.js";
import BucheDet from "./components/geoRef/BucheDet.js";
import ToDoListComp from "./components/toDo/ToDo.jsx";
import MachineProfile from "./components/registryComponent/machineProfile/MachineProfile.js";

const routes=[
     {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        name:"Home",
        auth:null,
        pageName:"Home",
        icon: Home,
         visibleOnCardMenu:false,
         homeComponent:HomePage
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        auth:'automatic_permission',
        name:"Automatiche",
        pageName:"Automatiche",
        icon: InProgress,
        visibleOnCardMenu:true,
        homeComponent:Automatiche
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        name:"Report clienti",
        pageName:"Report",
        icon: ContactInfo,
        visibleOnCardMenu:true,
        homeComponent:MachineProfile
    },  
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        name:"Attività",
        auth:null,
        pageName:"Attività",
        icon: List,
        visibleOnCardMenu:true,
        homeComponent:AttivitaDet1
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        name:"Analitiche",
        auth:null,
        pageName:"Analitiche",
        icon: Analytics,
        visibleOnCardMenu:true,
        homeComponent:Dashboard
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        name:"Anagrafiche",
        auth:null,
        pageName:"Anagrafiche",
        icon: UserWorker,
        visibleOnCardMenu:true,
        homeComponent:Anagrafiche
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona","Meccanico"],
        auth:'machine_maintenance',
        name:"Manutenzione Macchinari",
        pageName:"Manutenzione",
        icon: Configure,
        visibleOnCardMenu:true,
        homeComponent:Manutenzione
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        auth:null,
        name:"ToDo",
        pageName:"ToDo",
        icon: Checkmark,
        visibleOnCardMenu:true,
        homeComponent:ToDoListComp
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        auth:'map_permission',
        name:"Mappa",
        pageName:"Geo ref.",
        icon: Location,
        visibleOnCardMenu:true,
        homeComponent:BucheDet
    },
    {
        role:["admin","Admin_Cantiere","Admin_Zona"],
        auth:'warehouse_permission',
        name:"Magazzino",
        pageName:"Magazzino",
        icon: Cubes,
        visibleOnCardMenu:true,
        homeComponent:WareHouse
    },
]

export {routes};