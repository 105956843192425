import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab'; // Assicurati di importare i componenti correttamente
import { Tab, TextField, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { TextArea } from 'grommet';
import {Editor, EditorState} from 'draft-js';
import 'draft-js/dist/Draft.css';

const ProfileTab = (props) => {
    return (
        <TabContext value={props?.valueTab}>
            <TabList
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable tabs"
                onChange={props?.handleChange}
            >
                {props?.profile && Object.keys(props?.profile).length !== 0 && 
                    Object.entries(props?.profile['profile']).map(([key], index) => (
                        <Tab key={index} label={key} value={index} />
                    ))
                }
            </TabList>

            {props?.profile && Object.keys(props?.profile).length !== 0 && 
                Object.entries(props?.profile['profile']).map(([key, value], index) => (
                    <TabPanel key={index} value={index}>
                        {/* Controlla il valore di input */}
                        {value.input === 'choices' && (
                            <Autocomplete
                                options={value.accepted_value} // Array di opzioni
                                renderInput={(params) => <TextField {...params} label="Seleziona un'opzione" />}
                            />
                        )}
                        {value.input === 'text' && (
                            <TextArea
                                label="Inserisci testo"
                                variant="outlined"
                                value={value.value}
                            />
                        )}
                        {value.input === 'check' && (
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Accetta"
                            />
                        )}
                    </TabPanel>
                ))
            }
        </TabContext>
    );
};

export default ProfileTab;
