import React, { useState, useEffect } from "react";
import { Box, Grommet, Grid, Sidebar, Card, Select, CardHeader, CardFooter, CardBody, Text, Image, Button, Heading, ResponsiveContext, Tip } from 'grommet';
import {
    Home,
    UserWorker,
    Analytics,
    MapLocation,
    Configure,
    List,
    Notification as NotificationApp,
    Checkmark,
    Cubes,
    Task, FormClose, Plan,
    Pause,
    StopFill,
    Play,
} from "grommet-icons";
import  { Redirect } from 'react-router-dom';
import './css/index.css';
import moment from "moment";
import AccountBox from "./components/Account";
import Anagrafiche from "./components/registryComponent/Anagrafiche";
import AttivitaDet1 from "./components/activity/AttivitaDet1";
import Automatiche from "./components/activity/automatiche";
import BucheDet from "./components/geoRef/BucheDet";
import Manutenzione from "./components/maintenance/Manutenzione.js";
import Dashboard from "./components//dashboard/Dashboard"
import Notifiche from "./components/notifications/Notifiche.js";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import {useLazyQuery, useMutation, useSubscription} from '@apollo/client';
import {GET_TENANT_DATA, QUERY_GET_CHILDREN_AREA, QUERY_ME} from "./components/apolloClient/loginGQL";
import { GET_ACTIVITY_IN_PROGRESS_USER, ADD_AUTOMATIC_ACTIVITY, UPDATE_AUTOMATIC_ACTIVITY } from "./components/activity/AutomaticheGQL";
import {deleteCookie, extractRoleFromWhoami, extractRootTreeFromWhoami} from "./utilis/utils";
import {tenant,tenantType, tenantTypeFirstUpperCase} from "./components/tenant.js";
import {USER_NOTIFICATION} from "./components/notifications/notificationGQL.js";
import ToDoListComp  from "./components/toDo/ToDo.jsx";
import WareHouse from "./components/wareHouse/WareHouse.js";
import {Layer as LayerGrommet} from "grommet/components/Layer/index.js";
import AddMaintenance from "./components/registryComponent/maintenance/AddMaintenance.js";
import {routes} from "./routes.js";
import CardHome from "./components/CardHome/CardHome.js";
import SideBarDesktop from "./components/sideBarComp/SideBarDesktop.js";
import MachineProfile from "./components/registryComponent/machineProfile/MachineProfile";

const theme = {
  global: {
    input:{
      font:{
        weight: 0
      }
    },
    colors: {
      brand: '#397aa3', //#397aa3
      'accent-1': 'white',
      'focus': '#397aa3',
      "my-text-color": "",
      background: 'trasparent',
    },
    font: {
      family: '',
      size: '18px',
      height: '20px',

    },
  },
};

const HomePage = () => {

  /*CHANGE PER IMPLEMENTAZIONE RUOLI*/
  const [ruoli, setRuoli] = useState([]);
  const [notifications, setNotifications] = useState([]);
  /*END CHANGE PER IMPLEMENTAZIONE NOTIFICHE*/

  /*CHANGE PER IMPLEMENTAZIONE CANTIERI*/
  let [newCantiere, setNewCantiere] = useState(null);
  const [cantieri, setCantieri] = useState([]);
  const [areeId,setAreeId]=useState([]);
  const [page,setPage]=React.useState("Home"); //Da cambiare come parametro scheda home
  const [nomeDip,setNomeDip]=React.useState(" - ");
  const [showSideBarMob,setShowSideBarMob]=React.useState(false);
  const [landScapeError,setLandScapeError]=React.useState(false);
  const [smallSideBar,setSmallSideBar]=React.useState(false);
  const [activity,setActivity]=React.useState([]);
  const [userLogged,setUserLogged]=React.useState(null);
  const [toDoList,setToDoList]=React.useState([]);
  const [functionPermission,setFunctionPermission]=React.useState({});
  const scrollSidebar=React.useRef(null);
  const [showLayerMachineType,setShowLayerMachineType]=React.useState(false);

  const [addActivity]=useMutation(ADD_AUTOMATIC_ACTIVITY);
  const [updateActivity]=useMutation(UPDATE_AUTOMATIC_ACTIVITY);

  const [ queryGetChildrenArea
  ]  = useLazyQuery(QUERY_GET_CHILDREN_AREA, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const childrenArea=data[`${tenant}`][`area${tenantTypeFirstUpperCase}GetChildren`].map((area)=>area.id)
        //console.log("CHILDREN AR")
        sessionStorage.setItem("areeId",JSON.stringify(childrenArea))
        setAreeId(childrenArea);
    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });
    const [ queryGetTenantData
  ]  = useLazyQuery(GET_TENANT_DATA, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
        const tenantData=data["core_tenant"][0];
        if(tenantData){
            setFunctionPermission({...functionPermission,...tenantData})
        }
    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  const [ getNotification
  ] = useLazyQuery(USER_NOTIFICATION, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {

      const notifications=data[`${tenant}_users_usernotification`].map((el)=>el.users_notification)
      if(notifications){
        setNotifications(notifications);
      }
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ queryMe
  ]  = useLazyQuery(QUERY_ME, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      extractRoleFromWhoami(data,setRuoli,setCantieri,setNewCantiere,setAreeId,queryGetChildrenArea);
      setUserLogged({name:data[`${tenant}`]["whoami"].profile.name,surname:data[`${tenant}`]["whoami"].profile.surname,email:data[`${tenant}`]["whoami"].username});
      setFunctionPermission({modifyRegister: data[`${tenant}`]["whoami"].profile.modifyRegistry || false, supersetPermission: data[`${tenant}`]["whoami"].profile.supersetPermission || false, warehousePermission: data[`${tenant}`]["whoami"].profile.warehousePermission || false ,toDoPermission:data[`${tenant}`]["whoami"].profile.toDoPermission || false, modifyUser:data[`${tenant}`]["whoami"].profile.modifyUser || false, machineProductsPermission: data[`${tenant}`]["whoami"].profile.machineProductsPermission || false});
      sessionStorage.setItem("hideRegistry",data[`${tenant}`]["whoami"].profile.modifyRegistry);
      sessionStorage.setItem("userId",data[`${tenant}`]["whoami"].profile.id);
      queryGetActivityInProgress({variables:{user_id:sessionStorage.getItem("userId")}});
      getNotification({variables:{id:sessionStorage.getItem("userId")}})
      getToDo({variables:{id:sessionStorage.getItem("userId")}})
        queryGetTenantData({variables:{name:tenant}});
      },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });


  const [ queryGetActivityInProgress
  ]  = useLazyQuery(GET_ACTIVITY_IN_PROGRESS_USER, {
  fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if(data){
        const dataApp=data[`${tenant}_activity_activity${tenantType}`];
        if(dataApp){
          dataApp.forEach(item => {
            let fieldName = `jobs_job${tenantType}`;
            item.job= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
            delete item[fieldName]; // Elimina il vecchio campo
            fieldName = `machines_machine${tenantType}`;
            item.cliente= item[fieldName];  // Sposta il contenuto del campo specifico sotto 'user'
            item.tip_pause='Pausa '+item.job.name+' per '+item.cliente.name
            item.tip_stop='Stop '+item.job.name+' per '+item.cliente.name
            delete item[fieldName]; // Elimina il vecchio campo
          })
          setActivity(dataApp)
          console.log("data elaborated",dataApp);
        }
      }

    },
    onError:(error)=>{
      console.log(error);
    },
    notifyOnNetworkStatusChange: true, // did the work
  });

    const [ getToDo
      ] = useLazyQuery(USER_NOTIFICATION, {
        fetchPolicy: "no-cache",
        onCompleted: (data) => {
          const notifications=data[`${tenant}_users_usernotification`].map((el)=>el.users_notification)
          if(notifications){
            setNotifications(notifications);
          }
        },
        notifyOnNetworkStatusChange: true, // did the work
      });

  
      function handlePauseActivity(activityId){
        let thisActivity;
  
        activity.forEach((value,index)=>{
          if(value.id===activityId){
            thisActivity=value;
          }
        })
  
        let end=new Date()
        let data={variables:
          {
            id_activity:activityId,
            start:thisActivity.start,
            end:end,
            approved:"SI"
          }
        }
  
        let dataNewActivity={variables:
          {
            start:new Date(end.getTime() + 5 * 60 * 1000),
            end:new Date(end.getTime() + 35 * 60 * 1000),
            notes:thisActivity.notes,
            job:thisActivity.job.id,
            machine:thisActivity.cliente.id,
            data:moment(end).format("YYYY/MM/DD"),
            area:thisActivity.area_id,
            approved:"PAUSA",
            created_by_user:false,
            user:sessionStorage.getItem("userId")
          }
        }
  
        updateActivity(data).then((value)=>{
          alert("Attività aggiornata correttamente");
          addActivity(dataNewActivity).then((value)=>{
            alert("Attività aggiunta correttamente");
            setActivity([])
          })
          .catch((error)=>{
            console.error(error);
            alert("Errore durante l'operazione");
          }) 
          
        })
        .catch((error)=>{
          console.error(error);
          alert("Errore durante l'operazione");
        }) 
  
        console.log("DATA NEW ACTIVITY",dataNewActivity);
        console.log("DATA UPDATE ACTIVITY",data);
      }
  
      function handleStopActivity(activityId){
        let thisActivity;
  
        activity.forEach((value,index)=>{
          if(value.id===activityId){
            thisActivity=value;
          }
        })
  
        let end=new Date()
        let data={variables:
          {
            id_activity:activityId,
            start:thisActivity.start,
            end:end,
            approved:"SI"
          }
        }
  
        updateActivity(data).then((value)=>{
          alert("Attività aggiornata correttamente");
          setActivity([]);
        })
        .catch((error)=>{
          console.error(error);
          alert("Errore durante l'operazione");
        })
  
        
      }


  /**
   * Funzione che cambia il cantiere selezionato nel momento in cui viene selezionato nella Select
   * @param {*} option Cantiere selezionato nella Select della SideBar
   */
  function handleCantiere (option) {
      debugger;
      setNewCantiere(option);
      queryGetChildrenArea({variables:{id:option.map((el)=>el?.id)}});
      sessionStorage.setItem('Cantiere', JSON.stringify(option.map((el)=>el?.id)));
  }
  
  useEffect(() => {
    if(sessionStorage.getItem('Page')!==undefined && sessionStorage.getItem('Page')!==null){
      setPage(sessionStorage.getItem('Page'))
    }else{  //Primo avvio  
      setPage("Home");
    }
    if(sessionStorage.getItem('clickNotify')!==undefined && sessionStorage.getItem("clickNotify")!==null){
      sessionStorage.removeItem("clickNotify");
      sessionStorage.setItem("tab1",1);
      setPage("Manutenzione");
    }
    if(sessionStorage.getItem("smallSidebar")!==null){
      if(sessionStorage.getItem("smallSidebar")==="true"){
        setSmallSideBar(true);
      }else{
        setSmallSideBar(false)
      }
    }
    queryMe();
  }, []);

  React.useEffect(()=>{
      if(scrollSidebar && (page==="ToDo" || page==="Magazzino")){
          scrollToBottom()
      }
  },[page,scrollSidebar])

  const scrollToBottom = () => {
    const myDiv = scrollSidebar?.current;
    if(myDiv){
      myDiv.scrollTop = myDiv?.scrollHeight;
    }
  };


  function trovaCantierePerId(cantieri) {
      const cantieriSelIds = JSON.parse(sessionStorage.getItem("Cantiere"));

      if (!cantieriSelIds) {
        return null; // Restituisci null se non c'è un id valido in sessionStorage
      }
      let cantSel=[];
      for (let i = 0; i < cantieri.length; i++) {
          if(cantieriSelIds.includes(cantieri[i].id)){
              cantSel.push(cantieri[i]);
          }
      }
      return cantSel || null; // Restituisci l'oggetto trovato o null se non trovato
  }


  React.useEffect(()=>{
      if(ruoli[0] &&  ruoli[0].Ruolo !== 'admin'){
           if(sessionStorage.getItem("Cantiere")!==null){
                setNewCantiere(trovaCantierePerId(cantieri));
           }
      }
  },[cantieri])

  /**
   * Ogni volta che viene selezionata una pagina diversa la salvo in session storage.
   * Permette di ritornare all'ultima pagina visitata nel caso in cui venga ricaricata la pagina
   */
  useEffect(() => {
    sessionStorage.setItem('Page',page);
  }, [page]);

  const [width, setWidth]   = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  
    /**
     * Funzione che permette di sapere le dimensioni aggiornate dello schermo
     */
  const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }


  useEffect(() => {
      window.addEventListener("resize", updateDimensions);
      return () => window.removeEventListener("resize", updateDimensions);
  }, []);


    React.useEffect(()=>{
        console.log("Page",page)
    },[page])

  /**
   * Controllo in base a width e height l'orientamento dello schermo.
   * Per essere respondive blocco la web app in LandScape.
   */
  React.useEffect(()=>{
    if(width<1100){
      if(width<height){
        setLandScapeError(true);
      }else{
        setLandScapeError(false);
      }    
    }
  },[width,height])


  if (!sessionStorage.getItem("accessToken")) {
      return <Redirect to='/' />
  }


  function handleLogOut() {
    sessionStorage.clear();
    localStorage.clear();
    deleteCookie("accessToken");
    deleteCookie("refreshToken");
    window.location.href = window.location.origin + "/";
  }

  /*
    Componente che implementa la SideBar per dispositivi Mobile
  */
  function SideBarMobile(props){
    return(
        <Box
              height="97vh"
              width="100%"
              background="#FFFFFF"
              overflow="auto"
              style={{overflowX: 'hidden'}}
              pad="small"
              >
              <Box align="center" direction="column" gap="small" margin="medium" width="100%">
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Home"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() =>{setPage("Home");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Home color={page!="Home"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Home"?'black':'#FFFFFF'}>Home</Text>
                      </Box>
                  </Button>
                  {ruoli[0] !== undefined ?
                      ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Attività"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Attività");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <List color={page!="Attività"?'black':'#FFFFFF'}/>
                          <Text size="small" color={page!="Attività"?'black':'#FFFFFF'}>Attività</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?

                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Analitiche"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Analitiche");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Analytics color={page!="Analitiche"?'black':'#FFFFFF'}/>
                          <Text size="small" color={page!="Analitiche"?'black':'#FFFFFF'}>Analitiche</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Notifiche"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Notifiche");setShowSideBarMob(false)}}

                    >
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <NotificationApp color={page!="Notifiche"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Notifiche"?'black':'#FFFFFF'} >Notifiche</Text>
                      </Box>
                  </Button>
                  {
                  ruoli[0] !== undefined ?
                  ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ||  ruoli[0].Ruolo == 'Meccanico' ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Manutenzione"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Manutenzione");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Configure color={page!="Manutenzione"?'black':'#FFFFFF'} />
                          <Text size="small"  color={page!="Manutenzione"?'black':'#FFFFFF'}>Macchinari</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                      (ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona') ?
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Anagrafiche"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => {setPage("Anagrafiche");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <UserWorker color={page!="Anagrafiche"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Anagrafiche"?'black':'#FFFFFF'}>Anagrafiche</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                      (ruoli[0].Ruolo == 'Meccanico')  ?
                  <Button style={{width:!smallSideBar?'200px':"50px",fontSize:'10px',background:showLayerMachineType?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={() => setShowLayerMachineType(true)}>
                      <Box pad="10px" direction="row" align="center" gap="medium">
                          <Plan color={!showLayerMachineType?'black':'#FFFFFF'} />
                          {
                            !smallSideBar?
                          <Text size="xsmall" color={!showLayerMachineType?'black':'#FFFFFF'}>Intervalli Manutenzione</Text>
                          :
                          null
                          }
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                          ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ?
                  
                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Geo ref."?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Geo ref.");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <MapLocation color={page!="Geo ref."?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Geo ref."?'black':'#FFFFFF'}>Mappa</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                  {ruoli[0] !== undefined ?
                      (ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona') && functionPermission?.toDoPermission ?

                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="ToDo"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("ToDo");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Checkmark color={page!="ToDo"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="ToDo"?'black':'#FFFFFF'}>ToDo</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
                   {ruoli[0] !== undefined ?
                       (ruoli[0].Ruolo == 'admin' ||  ruoli[0].Ruolo == 'Admin_Cantiere' ||  ruoli[0].Ruolo == 'Admin_Zona' ||  ruoli[0].Ruolo == 'Meccanico') && functionPermission?.warehousePermission?

                  <Button style={{height:"80px",width:"95%",fontSize:'10px',background:page=="Magazzino"?'#397aa3':'#FFFFFF',color:'white',fontWeight:'bold',textTransform: 'uppercase',borderRadius:"20px"}} onClick={()=>{setPage("Magazzino");setShowSideBarMob(false)}}>
                      <Box pad="small" direction="row" align="center" gap="medium">
                          <Cubes color={page!="Magazzino"?'black':'#FFFFFF'} />
                          <Text size="small" color={page!="Magazzino"?'black':'#FFFFFF'}>Magazzino</Text>
                      </Box>
                  </Button>
                  :
                  null
                  :
                  null
                  }
              </Box>
          </Box>
    )
  }

  return (
   
    <Grommet theme={theme} className="font-link">
      <ResponsiveContext.Consumer>
        {size => (
         <Box fill height="97vh">
          {width>=1100?
            <Box direction="row">
                      { activity.length > 0 && activity.map((value, i) => (
                        <Box alignContent="center" justify="center" gap="small" marginTop="20px" direction="row" background={'trasparent'} width="140px" height="55px" style={{position:"absolute",zIndex:"99999", left:"50%", top:"90%", boxShadow:"0px 4px 4px rgba(0, 0, 0, 0.25)",borderRadius:"20px"}}>
                            <Tip content={value.tip_pause}>
                              <Pause size="large" color="#397aa3" onClick={(e)=>handlePauseActivity(value.id)}></Pause>
                            </Tip>
                            <Tip content={value.tip_stop}>
                              <StopFill size="large" color="red" onClick={(e)=>handleStopActivity(value.id)}></StopFill>
                            </Tip>
                        </Box>
                      ))
                      }
                <SideBarDesktop cantieri={cantieri} actualPage={page} setShowLayerMachineType={setShowLayerMachineType} scrollSidebar={scrollSidebar} handleCantiere={handleCantiere} newCantiere={newCantiere} ruoli={ruoli[0]} setRuoli={setRuoli} smallSideBar={smallSideBar} setSmallSideBar={setSmallSideBar} page={page} showLayerMachineType={showLayerMachineType} functionPermission={functionPermission} toDoList={toDoList} notifications={notifications} setPage={setPage}/>
                <Box width="100%">
                 {
                      showLayerMachineType && ruoli[0]?.Ruolo=="Meccanico" && (
                        <LayerGrommet  full={window.screen.width<1100} onEsc={()=>setShowLayerMachineType(false)} onClickOutside={()=>setShowLayerMachineType(false)}>
                        <Box height="100%" round="medium" pad="15px" background="#ecedf3">
                          <Button  alignSelf="end" icon={<FormClose color="grey" size="35px" onClick={()=>setShowLayerMachineType(false)}/>}></Button>
                          <AddMaintenance onEscLayer={()=>setShowLayerMachineType(false)}/>
                        </Box>
                      </LayerGrommet>
                      )
                 }
                { page==="Anagrafiche" && <Anagrafiche aree={areeId} ruoli={ruoli} modifyRegistry={functionPermission?.modifyRegister} modifyUser={functionPermission?.modifyUser} customer_permission={functionPermission?.customer_permission}/>}
                { page==="Attività" && <AttivitaDet1 aree={areeId}/>}
                { page==="Automatiche" && functionPermission?.automatic_permission && <Automatiche aree={areeId} activity={activity} setActivity={setActivity}/>}
                { page==="Report" &&  <MachineProfile aree={areeId}/>}
                { page==="Geo ref." && functionPermission?.map_permission && <BucheDet aree={areeId}/>}
                { page==="Manutenzione" && <Manutenzione aree={areeId} ruolo={ruoli[0]?.Ruolo}/>}
                { page==="Analitiche" && <Dashboard aree={areeId} exportCsvExternalCode={functionPermission?.export_csv_external_code} cantieri={cantieri} permissionSuperset={functionPermission?.supersetPermission} />}
                { page==="Notifiche" && <Notifiche aree={areeId} notifications={notifications} getNotification={getNotification} />}
                { page==="ToDo" && functionPermission?.toDoPermission && <ToDoListComp aree={areeId}/>}
                { page==="Magazzino" && functionPermission?.warehousePermission && functionPermission?.warehouse_permission && <WareHouse aree={areeId} role={ruoli} machineProductsPermission={functionPermission?.machineProductsPermission}/> }
                    { page==="Home" &&
                  <Box height="97vh" direction="column" background="#ecedf3">
                    <Box margin="2%" alignSelf="end">
                        {
                            userLogged &&
                               <AccountBox logout={handleLogOut} userLogged={userLogged}/>
                        }
                        
                        
                    </Box>
                    <Box alignSelf="center" overflow="auto" style={{overflowX:"hidden"}}>
                        <Grid
                            rows={['auto', 'auto']}
                            columns={['1fr', '1fr', '1fr']}
                            gap="xlarge"
                            pad="large">
                            {
                                routes.map((path)=>{
                                    if(path?.role.includes(ruoli[0]?.Ruolo) && path?.visibleOnCardMenu && (path?.auth==null || functionPermission[path?.auth])) {
                                        const Icon=path?.icon;
                                        return (
                                            <CardHome name={path?.name} setPage={setPage} pageName={path?.pageName}  icon={<Icon style={{width:'100px',height:'100px'}} color="brand" />} />
                                        )
                                    }
                                })
                            }
                        </Grid>
                    </Box>
                  </Box>
                }
               </Box>
            </Box>
            :
            <Box direction="column">
               <AppBar position="static" style={{ background: '#397aa3' }}>
                <Toolbar>
                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    TimeMate
                  </Typography>
                    {ruoli[0] !== undefined && cantieri[0] !== undefined ?
                      (ruoli[0].Ruolo == 'Admin_Cantiere' ?
                      <Select
                            options={cantieri}
                            labelKey={(option)=>option?.name}
                            value={newCantiere}
                            onChange={({ option }) => handleCantiere(option)}
                        />
                      :
                      (ruoli[0].Ruolo == 'Admin_Zona' ?
                      <Select
                          options={cantieri[0].Zona.map(cantiere => (cantiere))}
                          value={newCantiere}
                          onChange={({ option }) => handleCantiere(option)}
                        />
                        :
                        null
                      )
                      )
                      :
                      null
                      } 
                        <Box background={'#FFFFFF'} round="full" pad="small">
                       <IconButton
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"    
                          onClick={() => {
                          if(!showSideBarMob){
                            setShowSideBarMob(true);
                          }else{
                            setShowSideBarMob(false);
                          }      
                          }}
                        >
                    <MenuIcon />
                  </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
                {!showSideBarMob?
                    <Box width="100%">
                        { page==="Anagrafiche" && <Anagrafiche aree={areeId} ruoli={ruoli} modifyRegistry={functionPermission?.modifyRegister} modifyUser={functionPermission?.modifyUser} customer_permission={functionPermission?.customer_permission}/>}
                        { page==="Attività" && <AttivitaDet1 aree={areeId}/>}
                        { page==="Automatiche" && functionPermission?.automatic_permission && <Automatiche aree={areeId} activity={activity} setActivity={setActivity}/>}
                        { page==="Report" &&  <MachineProfile aree={areeId}/>}
                        { page==="Geo ref." && functionPermission?.map_permission && <BucheDet aree={areeId}/>}
                        { page==="Manutenzione" && <Manutenzione aree={areeId} ruolo={ruoli[0]?.Ruolo}/>}
                        { page==="Analitiche" && <Dashboard aree={areeId} exportCsvExternalCode={functionPermission?.export_csv_external_code} cantieri={cantieri} permissionSuperset={functionPermission?.supersetPermission} />}
                        { page==="Notifiche" && <Notifiche aree={areeId} notifications={notifications} getNotification={getNotification} />}
                        { page==="ToDo" && functionPermission?.toDoPermission && <ToDoListComp aree={areeId}/>}
                        { page==="Magazzino" && functionPermission?.warehousePermission && functionPermission?.warehouse_permission && <WareHouse aree={areeId} role={ruoli} machineProductsPermission={functionPermission?.machineProductsPermission}/> }
                               :
                  <Box height="97vh" direction="column" background="#ecedf3">
                    <Box margin="2%" alignSelf="end">
                      <AccountBox logout={handleLogOut} name={nomeDip}/>
                    </Box>
                    <Box alignSelf="center" overflow="auto" style={{overflowX:"hidden"}} >
                    <Grid
                        rows={['auto', 'auto']}
                        columns={['1fr', '1fr', '1fr']}
                        gap="xlarge"
                        pad="large">
                        {
                            routes.map((path)=>{
                                if(path?.role.includes(ruoli[0]?.Ruolo) && path?.visibleOnCardMenu) {
                                    const Icon=path?.icon;
                                    return (
                                        <CardHome name={path?.name} setPage={setPage} pageName={path?.pageName}  icon={<Icon style={{width:'100px',height:'100px'}} color="brand" />} />
                                    )
                                }
                            })
                        }
                         </Grid>
                    </Box>
                  </Box>
                }           
                </Box>
                :
                <SideBarMobile/>          
                }
              
            </Box>
            }
          </Box>)}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default HomePage;